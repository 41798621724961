
import { Component, Vue } from 'vue-property-decorator';
import {  readPatients, readLanguage } from '@/store/admin/getters';
import { dispatchGetPatients, dispatchGetPatientsNurse } from '@/store/admin/actions';
import { commitSwitchLanguage,commitSetEditHorse } from '@/store/admin/mutations';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { readHasAdminAccess, readUserProfile } from '@/store/main/getters';
import { IPatientProfile } from '@/interfaces';

@Component
export default class PatientUser extends Vue {
  public search = '';
  public items_per_page = [25, 50, 100, {"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}];
  public pagination =  {
    rowsPerPage: 25,
  };
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: ' Adress ',
      sortable: false,
      value: 'adress',
      align: 'left',
    },
  ];
  public headers_german = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: ' Adresse ',
      sortable: false,
      value: 'adress',
      align: 'left',
    },
  ];

  get patients() {
    return readPatients(this.$store);
  }

  public async mounted() {
    if(readHasAdminAccess(this.$store)) {
      await dispatchGetPatients(this.$store);
    } else {
      await dispatchGetPatientsNurse(this.$store);
    }
  }

  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }
  
  get me() {
    return readUserProfile(this.$store);
  }

  public patientAdress(profile: IPatientProfile) {
    var adress = "";
    if(profile.road) {
      adress += profile.road;
    }
    if(profile.house_number) {
      adress += " " + profile.house_number + ", ";
    }
    if(profile.postcode) {
      adress += profile.postcode.toString() + " ";
    }
    if(profile.city) {
      adress += profile.city;
    }
    if(adress === "") return "-------";
    return adress;
  }

  public patientName(profile: IPatientProfile) {
    var name = "";
    if(profile.firstname) {
      name += profile.firstname + " ";
    }
    if(profile.lastname) {
      name += profile.lastname;
    }
    if(name === "") return "-------";
    return name;
  }

  public makeTable() {
    var table = new Array<any>();
    this.patients.forEach((element) => {
      let arrayElement: string[] = [];
      arrayElement.push(element.id.toString());
      arrayElement.push(this.patientName(element));
      arrayElement.push(this.patientAdress(element));
      table.push(Object.values(arrayElement));
    });
    return table;
  }

  public gotoOverview(id: string, edit: boolean) {
    commitSetEditHorse(this.$store, edit);
    this.$router.push({name: 'main-horse-overview', params: {id: id}});
  }

  public download() {
    var pdf = new jsPDF();
    var textPDF = '';
    pdf.setFontSize(25);
    if(readHasAdminAccess(this.$store)) {
      textPDF = 'Full Horse List';
      pdf.text(textPDF, 75, 15);
    } else {
      textPDF = "Horse List of " + this.me?.firstname + " " + this.me?.lastname;
      pdf.text(textPDF, 10, 10);
    }
    if(this.patients.length > 0){
      autoTable(pdf, {
        head: [["ID", "Name", "Adress"]],
        body: this.makeTable(),
        headStyles: {
          fillColor: [192, 0, 0],
        },
        startY: 40,
        theme: "striped",
      }); 
    }
    pdf.save('pferde.pdf');
  }

  public download_german() {
    var pdf = new jsPDF();
    var textPDF = '';
    pdf.setFontSize(25);
    if(readHasAdminAccess(this.$store)) {
      textPDF = 'Vollständige Pferdeliste';
      pdf.text(textPDF, 55, 15);
    } else {
      textPDF = "Pferdeliste von " + this.me?.firstname + " " + this.me?.lastname;
      pdf.text(textPDF, 10, 10);
    }
    if(this.patients.length > 0){
      autoTable(pdf, {
        head: [["ID", "Name", "Adresse"]],
        body: this.makeTable(),
        headStyles: {
          fillColor: [192, 0, 0],
        },
        startY: 40,
        theme: "striped",
      }); 
    }
    pdf.save('horses.pdf');
  }

  public click() {
    this.$router.push('main/Dashboard.vue');
  }
}
